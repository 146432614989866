import { useState, useEffect } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
// Authentication layout components
import { CircularProgress, Switch, Typography, Box, Button, TextField, Link, Card, IconButton, InputAdornment } from "@mui/material";
// Images
import bgImage from "../../../assets/SaaS_SignIn.png";
import chamberLogo from "../../../assets/chamber_logo.png";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface Credentials {
  email: string;
  password: string;
}

function Basic() {
  const [cred, setCred] = useState<Credentials>({ email: "", password: "" });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const handleClickShowPassword = (): void => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCred({
      ...cred,
      [name]: value,
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const password = params.get('password');

    if (email && password) {
      const login = async () => {
        try {
          const apiUrl = "https://api.pixacci.com/chamber/login";
          const updatedCred = { email, password, role: "chamber" };
          const response: AxiosResponse<{
            success: boolean;
            message: { user_id: string; accessToken: string };
          }> = await axios.post(apiUrl, updatedCred);
          if (response.data.success) {
            localStorage.setItem("user_id", response.data.message.user_id);
            localStorage.setItem("token", response.data.message.accessToken);
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("role", "admin");
            enqueueSnackbar("Logged in successfully.", { variant: "success" });
            navigate("/dashboard");

            window.location.reload();
          } else {
            enqueueSnackbar("Login failed, something went wrong.", {
              variant: "error",
            });
            console.log("Login failed, something went wrong.");
          }
        } catch (error: AxiosError | any) {
          enqueueSnackbar(
            "Login failed, Check your credentials or User not found.",
            { variant: "error" }
          );
          console.log(error);
        }
      };

      login();
    }
  }, []);

  const handleLogin = async () => {
    try {
      const apiUrl = "https://api.pixacci.com/chamber/login";
      const updatedCred = { ...cred, role: "chamber" };
      const response: AxiosResponse<{
        success: boolean;
        message: { user_id: string; accessToken: string };
      }> = await axios.post(apiUrl, updatedCred);
      if (response.data.success) {
        localStorage.setItem("user_id", response.data.message.user_id);
        localStorage.setItem("token", response.data.message.accessToken);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("role", "admin");
        enqueueSnackbar("Logged in successfully.", { variant: "success" });
        navigate("/dashboard");
        window.location.reload();
      } else {
        enqueueSnackbar("Login failed, something went wrong.", {
          variant: "error",
        });
        console.log("Login failed, something went wrong.");
      }
    } catch (error: AxiosError | any) {
      enqueueSnackbar(
        "Login failed, Check your credentials or User not found.",
        { variant: "error" }
      );
      console.log(error);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="stretch"
      minHeight="100vh"
      sx={{ backgroundColor: "#f5f5f5" }}
    >
      <Box
        sx={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "3rem",
          backgroundColor: "#fff",
          position: "relative",
          marginTop: "-8rem",
        }}
      >

        <Card
          sx={{
            width: "100%",
            maxWidth: "400px",
            padding: "2rem",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <Box display="flex" justifyContent="center" sx={{ marginBottom: "1rem" }}>
            <img src={chamberLogo} alt="Chamber Logo" style={{ maxWidth: "150px", height: "auto" }} />
          </Box>
          <Typography variant="h3" align="center" fontWeight="bold" gutterBottom sx={{ marginBottom: "2rem", color: "#6c5ce7" }}>
            Welcome, Back!
          </Typography>
          <Typography variant="subtitle2" align="center" sx={{ marginBottom: "2rem", color: "#6c757d" }}>
            Enter your email and password to sign in
          </Typography>

          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            name="email"
            onChange={handleChange}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            name="password"
            onChange={handleChange}
            sx={{ marginBottom: "1rem" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ marginBottom: "1rem" }}>
            <Box display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} color="primary" />
              <Typography variant="body2" sx={{ marginLeft: "0.5rem" }}>
                Remember me
              </Typography>
            </Box>
          </Box>

          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "#6c5ce7",
              color: "#fff",
              padding: "0.75rem",
              fontSize: "1rem",
              "&:hover": { backgroundColor: "#5e4cc7" },
            }}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
          </Button>
        </Card>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            padding: "1rem",
            color: "#6c757d",
            backgroundColor: "#fff",
          }}
        >
          <Typography variant="body2">
            Made by <Link href="https://pixaflip.com/" target="_blank" sx={{ color: "#6c5ce7", textDecoration: "none" }}>Pixaflip</Link>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: "50%",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </Box>
  );
}

export default Basic;