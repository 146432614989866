import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Box,
  Typography,
  Card,
  Button
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MapComponent from "../MapComponent";
import { AddLounge, GetLoungeById, UpdateLounge } from "../../../Redux/action";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import useFormValidator from "../../../utils/form_validation";
import { addLoungeValidations } from "../../../utils/user_Validation";
import DeleteIcon from "@mui/icons-material/Delete";
import { State, City } from "country-state-city";
import {
  useAppSelector,
  RootState,
  useAppDispatch,
} from "../../../Redux/store";
import { Lounge as LoungeInterface } from "../../../utils/types";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";

const widthStyle = {
  width: "45%",
  "@media (max-width: 600px)": {
    width: "100%",
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Lounge: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const type = location?.state?.type;
  const idFromLocation = location?.state?.id;
  const { loungeById } = useAppSelector(
    (store: RootState) => store.LoungeManagementReducer
  );
  const chamber_id = localStorage.getItem("user_id");
  const [storedMapLocation, setStoredMapLocation] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 0,
    lng: 0,
  });
  const [dateError, setDateError] = useState("");
  const [extendedHourAllowed, setExtendedHourAllowed] = useState("no");
  const [imagePreview, setImagePreview] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const docUrl = "https://pixa-chambers-bucket.s3.ap-south-1.amazonaws.com/";
  const [states, setStates] = useState<{ name: string; isoCode: string }[]>([]);
  const [cities, setCities] = useState<{ name: string }[]>([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const handleLocationChange = (newPosition: any) => {
    setStoredMapLocation(newPosition);
    setPayload((prevPayload: any) => ({
      ...prevPayload,
      location: newPosition,
    }));
  };

  const payloadObj: LoungeInterface = {
    chamber_id: +chamber_id!,
    name: "",
    capacity: 0,
    address: "",
    city: "",
    state: "",
    pincode: 0,
    contact_person: "",
    contact_number: 0,
    contact_person_email: "",
    booking_price_hour: 0,
    current_discount: 0,
    applicable_deposit: 0,
    member_price: 0,
    nonmember_price: 0,
    extended_hour_allowed: extendedHourAllowed,
    extended_hours: "",
    booking_from: "",
    booking_till: "",
    opening_time: "",
    closing_time: "",
    location: storedMapLocation,
  };

  const [payload, setPayload] = useState<LoungeInterface>(
    idFromLocation
      ? ({
          ...loungeById,
          location: JSON.stringify(loungeById?.location),
        } as LoungeInterface)
      : { ...payloadObj, city: "", state: "" }
  );

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    // console.log("event", event.target)
    const selectedStateCode = event.target.value;
    setSelectedState(selectedStateCode);
    const citiesData = City.getCitiesOfState("IN", selectedStateCode);
    setCities(citiesData);

    setPayload({ ...payload, state: selectedStateCode });
  };

  const handleCityChange = (event: SelectChangeEvent<string>) => {
    const selectedCityValue = event.target.value;
    setSelectedCity(selectedCityValue);
    setPayload({ ...payload, city: selectedCityValue });
  };

  const [fileInputs, setFileInputs] = useState({
    images: null,
  });
  const { errors, validateForm } = useFormValidator(
    payload,
    addLoungeValidations
  );

  const handleExtendedHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtendedHourAllowed(e.target.value);
    setPayload({
      ...payload,
      extended_hour_allowed: e.target.value,
      extended_hours: "",
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const files = e.target.files?.[0];
    if (name === "images" || name === "terms_condition") {
      if (name === "images") {
        const temp = files && URL.createObjectURL(files);
        if (temp) {
          setImagePreview(temp);
        }
      }
      setFileInputs({ ...fileInputs, [name]: files });
    } else {
      if (name === "extended_hour_allowed" && value === "no") {
        setPayload((prevPayload: any) => ({
          ...prevPayload,
          [name]: value,
          extended_hours: "",
        }));
      } else {
        setPayload({ ...payload, [name]: value });
      }
    }

    if (
      name === "booking_from" &&
      payload.booking_till &&
      value > payload.booking_till
    ) {
      setDateError("Starting date cannot be later than ending date");
    } else if (
      name === "booking_till" &&
      payload.booking_from &&
      value < payload.booking_from
    ) {
      setDateError("Ending date cannot be earlier than starting date");
    } else {
      setDateError("");
    }
  };

  const handleResponseAddLounge = (
    success = false,
    res: { data: { message: any; error: any } }
  ) => {
    if (success) {
      enqueueSnackbar(`${"Lounge added successfully."}`, { variant: "success" });
      navigate("/lounge");
    } else {
      if (res?.data?.message) {
        enqueueSnackbar(`${res?.data?.message}`, { variant: "error" });
      } else if (res?.data?.error) {
        enqueueSnackbar(`${res?.data?.error}`, { variant: "error" });
      } else {
        enqueueSnackbar(`Something went wrong, Please check all fields.`, {
          variant: "error",
        });
      }
    }
  };

  const handleResponseUpdateLounge = (
    success = false,
    res: { data: { message: any; error: any } }
  ) => {
    if (success) {
      enqueueSnackbar(`${res?.data?.message}`, { variant: "success" });
      navigate("/lounge");
      window.location.reload();
    } else {
      enqueueSnackbar(`${res?.data?.error}`, { variant: "error" });
    }
  };

  const handleAddLounge = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
  
    const validateFormError = validateForm();
    if (!validateFormError) {
      var formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (key !== "lounge_image") {
          if (key === "image_path") {
            var imageTemp = JSON.stringify(value);
            formData.append(key, imageTemp);
          } else {
            formData.append(key, value);
          }
        }
      });
  
      Object.entries(fileInputs).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });
  
      if (type === "add") {
        dispatch<any>(AddLounge(formData, handleResponseAddLounge));
      } else {
        dispatch<any>(
          UpdateLounge(idFromLocation, formData, handleResponseUpdateLounge)
        );
      }
    } else {
      enqueueSnackbar(
        "Form validation failed. Please fill all the required fields.",
        { variant: "error" }
      );
    }
  };
  
  const handleRemoveImages = (i: any) => {
    const temp = payload?.lounge_image?.filter((item, index: number) => {
      return index !== i;
    });
    setPayload({ ...payload, image_path: temp, lounge_image: temp });
  };
  
  useEffect(() => {
    const statesData = State.getStatesOfCountry("IN");
    setStates(statesData);
  }, []);
  
  const handleInitialCities = (stateCode: string): void => {
    const citiesData = City.getCitiesOfState("IN", stateCode);
    setCities(citiesData);
  };
  
  useEffect(() => {
    if (idFromLocation) {
      dispatch<any>(GetLoungeById(idFromLocation, handleInitialCities));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idFromLocation]);
  
  useEffect(() => {
    if (loungeById) {
      const updatedPayload = {
        ...loungeById,
        location:
          typeof loungeById?.location === "object"
            ? JSON.stringify(loungeById?.location)
            : loungeById.location,
        lounge_image:
          typeof loungeById?.lounge_image === "string"
            ? JSON.parse(loungeById?.lounge_image)
            : loungeById?.lounge_image,
      };
      setPayload(updatedPayload as LoungeInterface);
    }
  }, [loungeById]);
  
  useEffect(() => {
    if (type === "add") {
      setPayload(payloadObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // console.log("payload", payload?.location);
  // console.log("selectedCity outside", selectedCity);
  // console.log("Lounge location", loungeById?.location);
  // console.log("loungeById?.data", loungeById?.data)
  // console.log("slots_timing", payload?.slots_timing);
  // console.log("storedMapLocation Lounge", storedMapLocation);

  return (
    <form onSubmit={handleAddLounge}>
      <DashboardLayout>
        <DashboardNavbar />
        <Box pt={6} pb={3}>
          <Grid container spacing={3} justifyContent="flex-start">
            <Grid item xs={12}>
              <Card>
                <Box pt={3}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={3}
                    py={2}
                  >
                    <Typography
                      variant="h5"
                      color="black"
                      sx={{ ml: 1, mb: -2 }}
                    >
                      Lounge Management
                    </Typography>
                  </Box>
                </Box>
        <MDBox
          {...({} as any)}
          mx={2}
          mt={3}
          py={3}
          p={4}
          mb={2}
          variant="gradient"
          borderRadius="lg"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            gap={3}
            pb={2}
          >
            <MDInput
              {...({} as any)}
              required
              type="text"
              label="Lounge Name"
              name="name"
              value={payload?.name || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <MDInput
              {...({} as any)}
              required
              type="text"
              label="Capacity"
              name="capacity"
              value={payload?.capacity || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <MDInput
              {...({} as any)}
              required
              type="text"
              label="Address"
              name="address"
              value={payload?.address || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <FormControl required sx={widthStyle}>
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                style={{ height: "45px" }}
                value={payload?.state || selectedState}
                onChange={handleStateChange}
              >
                {states.map((state) => (
                  <MenuItem
                    key={state.isoCode}
                    value={state.isoCode}
                    // name={state.name}
                  >
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl required sx={widthStyle}>
              <InputLabel>City</InputLabel>
              <Select
                label="City"
                style={{ height: "45px" }}
                value={payload?.city || selectedCity}
                onChange={handleCityChange}
              >
                {cities.map((city) => (
                  <MenuItem key={city.name} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MDInput
              {...({} as any)}
              required
              type="number"
              label="Pincode"
              name="pincode"
              value={payload?.pincode || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <MDInput
              {...({} as any)}
              required
              type="text"
              label="Contact Person Name"
              name="contact_person"
              value={payload?.contact_person || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <MDInput
              {...({} as any)}
              required
              type="email"
              label="Contact Person Email"
              name="contact_person_email"
              value={payload?.contact_person_email || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <MDInput
              {...({} as any)}
              required
              type="number"
              label="Contact Person Phone Number"
              name="contact_number"
              value={payload?.contact_number || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <MDInput
              {...({} as any)}
              required
              type="number"
              label="Booking Price per hour"
              name="booking_price_hour"
              value={payload?.booking_price_hour || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <MDInput
              {...({} as any)}
              required
              type="text"
              label="Discount"
              name="current_discount"
              value={payload?.current_discount || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <MDInput
              {...({} as any)}
              required
              type="text"
              label="Applicable Deposit"
              name="applicable_deposit"
              value={payload?.applicable_deposit || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <MDInput
              {...({} as any)}
              required
              type="text"
              label="Member Price"
              name="member_price"
              value={payload?.member_price || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
            <MDInput
              {...({} as any)}
              required
              type="text"
              label="Non-member Price"
              name="nonmember_price"
              value={payload?.nonmember_price || ""}
              margin="dense"
              sx={widthStyle}
              onChange={handleChange}
            />
          <Grid container justifyContent="center"> {/* Centering container */}
            <Grid item xs={12} md={6}> 
            <FormControl sx={widthStyle} component="fieldset" required>
              <FormLabel>Extended hour allowed</FormLabel>
              <RadioGroup
                row
                aria-label="extended_hour_allowed"
                name="extended_hour_allowed"
                value={extendedHourAllowed}
                onChange={handleExtendedHourChange}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Allowed"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="Not allowed"
                />
              </RadioGroup>
            </FormControl>
            </Grid>
            </Grid>
            {/* extended_hours int NULL,(Enable this field if above field is Yes) */}
            {extendedHourAllowed === "yes" && (
              <MDInput
                {...({} as any)}
                required
                type="number"
                label="Extended hours"
                name="extended_hours"
                value={
                  extendedHourAllowed === "yes"
                    ? payload?.extended_hours || ""
                    : ""
                }
                margin="dense"
                sx={widthStyle}
                onChange={handleChange}
              />
            )}

            <Grid container direction="row" justifyContent="space-between">
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                gap={1}
                pt={2}
                sx={widthStyle}
              >
                <MDTypography
                  {...({} as any)}
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: "400" }}
                >
                  Booking Availability (from)
                </MDTypography>
                <MDInput
                  {...({} as any)}
                  required
                  type="date"
                  name="booking_from"
                  value={payload?.booking_from || ""}
                  margin="dense"
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                gap={3}
                pt={2}
                sx={widthStyle}
              >
                <MDTypography
                  {...({} as any)}
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: "400" }}
                >
                  Booking Availability (to)
                </MDTypography>
                <MDInput
                  {...({} as any)}
                  required
                  type="date"
                  name="booking_till"
                  value={payload?.booking_till || ""}
                  margin="dense"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between">
            <Grid container direction="row" justifyContent="space-between">
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                gap={1}
                pt={2}
                sx={widthStyle}
              >
                <MDTypography
                  {...({} as any)}
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: "400" }}
                >
                  Lounge Opening Time
                </MDTypography>
                <MDInput
                  {...({} as any)}
                  required
                  type="time"
                  name="opening_time"
                  value={payload?.opening_time || ""}
                  margin="dense"
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                gap={3}
                pt={2}
                sx={widthStyle}
              >
                <MDTypography
                  {...({} as any)}
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: "400" }}
                >
                  Lounge Closing Time
                </MDTypography>
                <MDInput
                  {...({} as any)}
                  required
                  type="time"
                  name="closing_time"
                  value={payload?.closing_time || ""}
                  margin="dense"
                  onChange={handleChange}
                />
              </Grid>
              </Grid>

              <Grid
                container
                direction="column"
                justifyContent="space-between"
                gap={3}
                pt={2}
                sx={widthStyle}
              >
                <MDTypography
                  {...({} as any)}
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: "400" }}
                >
                  Upload Lounge Images
                </MDTypography>
                <MDInput
                  {...({} as any)}
                  type="file"
                  name="images"
                  margin="dense"
                  // sx={widthStyle}
                  onChange={handleChange}
                  // multiple
                />

                {imagePreview ? (
                  <img
                    src={`${imagePreview}`}
                    alt="Lounge images"
                    style={{
                      width: "50%",
                      aspectRatio: "3/2",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <MDTypography
                    {...({} as any)}
                    variant="h6"
                    sx={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    No latest images selected yet.
                  </MDTypography>
                )}
              </Grid>
              {type === "edit" && payload?.lounge_image ? (
                <ImageList
                  sx={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "2rem",
                    padding: "5px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "@media (max-width: 961px)": {
                      justifyContent: "center",
                    },
                  }}
                  rowHeight={164}
                >
                  {type === "edit" &&
                    payload?.lounge_image &&
                    payload?.lounge_image.map(
                      (file: any, index: React.Key | null | undefined) => (
                        <ImageListItem key={index} style={{ width: "200px" }}>
                          <img
                            src={`${docUrl}${file}`}
                            alt={"Lounge Images"}
                            loading="lazy"
                            style={{
                              width: "100%",
                              aspectRatio: "3/2",
                              objectFit: "contain",
                            }}
                          />
                          <MDButton
                            {...({} as any)}
                            component="button"
                            variant="body2"
                            onClick={() => handleRemoveImages(index)}
                            fullWidth
                            style={{ marginTop: "6px" }}
                          >
                            Remove the photo <DeleteIcon />
                          </MDButton>
                        </ImageListItem>
                      )
                    )}
                </ImageList>
              ) : null}
            </Grid>

            <MapComponent
              storedMapLocation={
                loungeById?.location as { lat: number; lng: number }
              }
              onPositionChange={handleLocationChange}
            />
          </Grid>
          {(errors?.name && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.name}
            </span>
          )) ||
            (errors?.capacity && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.capacity}
              </span>
            )) ||
            (errors?.address && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.address}
              </span>
            )) ||
            (errors?.state && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.state}
              </span>
            )) ||
            (errors?.city && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.city}
              </span>
            )) ||
            (errors?.pincode && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.pincode}
              </span>
            )) ||
            (errors?.contact_person && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.contact_person}
              </span>
            )) ||
            (errors?.contact_person_email && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.contact_person_email}
              </span>
            )) ||
            (errors?.contact_number && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.contact_number}
              </span>
            )) ||
            (errors?.booking_price_hour && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.booking_price_hour}
              </span>
            )) ||
            (errors?.current_discount && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.current_discount}
              </span>
            )) ||
            (errors?.applicable_deposit && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.applicable_deposit}
              </span>
            )) ||
            (errors?.member_price && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.member_price}
              </span>
            )) ||
            (errors?.nonmember_price && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.nonmember_price}
              </span>
            )) ||
            (errors?.extended_hours && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.extended_hours}
              </span>
            )) ||
            (errors?.booking_from && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.booking_from}
              </span>
            )) ||
            (errors?.booking_till && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.booking_till}
              </span>
            )) ||
            (errors?.lounge_image && (
              <span style={{ fontSize: "12px", color: "red" }}>
                {errors?.lounge_image}
              </span>
            ))}

          {dateError && (
            <span style={{ fontSize: "12px", color: "red" }}>{dateError}</span>
          )}
        </MDBox>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          p={2}
          pb={0.5}
        >
                  <MDButton
                    {...({} as any)}
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    {type === "add" ? "Add Lounge" : "Update"}
                  </MDButton>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </DashboardLayout>
    </form>
  );
};

export default Lounge;
