import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { isTokenExpired, logout } from "utils/authUtil";
import { enqueueSnackbar } from "notistack";
import PeopleIcon from '@mui/icons-material/People';
import DomainIcon from '@mui/icons-material/Domain';
import WeekendIcon from '@mui/icons-material/Weekend';
import EventIcon from '@mui/icons-material/Event';

function Dashboard() {
  const [dashboardData, setDashboardData] = useState({
    events: 5,
    members: 7,
    halls: 7,
    lounges: 7,
  });

  // const authToken = localStorage.getItem("token");

  // const fetchDashboard = async () => {
  //   if (isTokenExpired(authToken)) {
  //     enqueueSnackbar("Login is expired, Sign-in again to continue", { variant: "error" });
  //     setTimeout(() => {
  //       logout();
  //     }, 1000);
  //     return; // Exit early if token is expired
  //   }
  // };

  // const fetchDashboard = async () => {
  //   if (isTokenExpired(authToken)) {
  //     enqueueSnackbar("Login is expired, Sign-in again to continue", { variant: "error" });
  //     setTimeout(() => {
  //       logout();
  //     }, 1000);
  //     return; // Exit early if token is expired
  //   }

  //   // try {
  //   // Dashboard API
  //   //   setDashboardData(data); // Assume data has the new structure
  //   // } catch (error) {
  //   //   enqueueSnackbar("Failed to fetch dashboard data", { variant: "error" });
  //   // }
  // };

  // useEffect(() => {
  //   const currentPath = window.location.pathname;
  //   // Check if the reload has already occurred
  //   const hasReloaded = sessionStorage.getItem('hasReloaded');
  //   if (currentPath === '/dashboard') {
  //     fetchDashboard();
  //     if (!hasReloaded) {
  //        // Set a timeout to reload after 1 second
  //   const reloadTimeout = setTimeout(() => {
  //     window.location.reload();

  //     // Mark reload as done in sessionStorage
  //     sessionStorage.setItem('hasReloaded', 'true');
  //   }, 1000); // 1000ms = 1 second

  //   // Cleanup the timeout on component unmount
  //   return () => clearTimeout(reloadTimeout);
  //   }
  // }
  // }, [authToken]);
  // useEffect(() => {
  //   const hasLoaded = sessionStorage.getItem('hasLoadedDashboard');

  //   if (!hasLoaded) {
  //     fetchDashboard();
  //     sessionStorage.setItem('hasLoadedDashboard', 'true');
  //   }
  // }, [authToken]);
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon={<PeopleIcon />}
                title="Members"
                count={dashboardData.members}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon={<DomainIcon />}
                title="Halls"
                count={dashboardData.halls}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon={<WeekendIcon />}
                title="Lounges"
                count={dashboardData.lounges}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon={<EventIcon />}
                title="Events"
                count={dashboardData.events}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
