import * as React from "react";
import { Grid, ImageList, ImageListItem, Card, Box, Button, Typography } from "@mui/material";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  AddEquipments,
  GetEquipmentsById,
  UpdateEquipmentById,
} from "../../../Redux/action";
import { useSnackbar } from "notistack";
import useFormValidator from "../../../utils/form_validation";
import { addEquipmentValidations } from "../../../utils/user_Validation";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useAppDispatch,
  useAppSelector,
  RootState,
} from "../../../Redux/store";
import { Equipment as EquipmentInterface } from "../../../utils/types";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

const widthStyle = {
  width: "45%",
  "@media (max-width: 600px)": {
    width: "100%",
  },
};

interface IEquipment extends EquipmentInterface {
  files: string[];
}

const Equipment: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { type, hallId }: { type: string; hallId: number } = state;
  const idFromLocation = state?.id;
  const { enqueueSnackbar } = useSnackbar();
  const { equipsById } = useAppSelector(
    (store: RootState) => store.EquipmentReducer
  );
  const [imagePreview, setImagePreview] = useState("");
  const docUrl = "https://pixa-chambers-bucket.s3.ap-south-1.amazonaws.com/";
  const [fileInputs, setFileInputs] = useState<{
    files: File | null | undefined;
  }>({
    files: null,
  });

  const payloadObj = {
    hall_id: hallId,
    equipment_name: "",
    equipment_quantity: 0,
    equipment_desc: "",
    member_discount: 0,
    booking_price: 0,
    files: [],
  };
  const [payload, setPayload] = useState<IEquipment>(
    idFromLocation
      ? ({
          ...equipsById,
          equipment_images: Array.isArray(equipsById?.equipment_images)
            ? equipsById?.equipment_images
            : [],
          files: Array.isArray(equipsById?.files) ? equipsById?.files : [],
        } as IEquipment)
      : payloadObj
  );

  const { errors, validateForm } = useFormValidator(
    payload,
    addEquipmentValidations
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    const filesValue = e.target.files?.[0];
    if (name === "files") {
      const temp = filesValue && URL.createObjectURL(filesValue);
      setImagePreview(temp as string);
      setFileInputs({ ...fileInputs, [name]: filesValue });
    } else {
      setPayload({ ...payload, [name]: value });
    }
  };

  const handleRemoveImages = (i: number) => {
    const updatedImages = Array.isArray(payload?.equipment_images)
      ? payload?.equipment_images.filter(
          (item: string, index: number) => index !== i
        )
      : [];

    setPayload({
      ...payload,
      equipment_images: updatedImages || [],
    });
  };

  const handleResponseAddEquipments = (success = false, res: any) => {
    if (success) {
      enqueueSnackbar(`${"Equipment added successfully."}`, {
        variant: "success",
      });
      navigate("/equipment-management");
    } else {
      if (res?.data?.message) {
        enqueueSnackbar(`${res?.data?.message}`, { variant: "error" });
      } else if (res?.data?.error) {
        enqueueSnackbar(`${res?.data?.error}`, { variant: "error" });
      } else {
        enqueueSnackbar(`Something went wrong, Please check all fields.`, {
          variant: "error",
        });
      }
    }
  };

  const handleResponseUpdateEquipment = (success = false, res: any) => {
    if (success) {
      enqueueSnackbar(`${res?.data?.message}`, { variant: "success" });
      navigate("/equipment-management");
    } else {
      enqueueSnackbar(`${res?.data?.error}`, { variant: "error" });
    }
  };

  const handleAddUser = async () => {
    const validateFormError = validateForm();

    if (!validateFormError) {
      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (key === "equipment_images") {
          var stringifiedImg = JSON.stringify(value);
          formData.append(key, stringifiedImg);
        } else {
          formData.append(key, String(value) as string);
        }
      });

      Object.entries(fileInputs).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });

      if (type === "add") {
        dispatch<any>(AddEquipments(formData, handleResponseAddEquipments));
      } else {
        dispatch<any>(
          UpdateEquipmentById(
            idFromLocation,
            formData,
            handleResponseUpdateEquipment
          )
        );
      }
    } else {
      enqueueSnackbar(
        "Form validation failed. Please fill in all required fields.",
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    if (idFromLocation) {
      dispatch<any>(GetEquipmentsById(idFromLocation));
    }
  }, [idFromLocation, dispatch]);

  useEffect(() => {
    if (equipsById) {
      const updatedPayload = {
        ...equipsById,
        equipment_images:
          typeof equipsById?.equipment_images === "string"
            ? JSON.parse(equipsById?.equipment_images)
            : equipsById?.equipment_images,
      };
      setPayload(updatedPayload);
    }
  }, [equipsById]);

  useEffect(() => {
    if (type === "add") {
      setPayload(payloadObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("equipsById", equipsById.data);
  // console.log("payload", payload);
  // console.log("fileInputs", fileInputs);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={3} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ ml: 1, mb: -2 }}
                  >
                    Equipment Management
                  </Typography>
                </Box>
              </Box>
      <MDBox
        {...({} as any)}
        mx={2}
        mt={3}
        py={3}
        p={4}
        mb={2}
        borderRadius="lg"
        variant="gradient"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          gap={3}
          pb={2}
        >
          <MDInput
            {...({} as any)}
            required
            type="text"
            label="Equipment Name"
            name="equipment_name"
            value={payload?.equipment_name || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <MDInput
            {...({} as any)}
            required
            type="number"
            label="Equipment Quantity"
            name="equipment_quantity"
            value={payload?.equipment_quantity || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <MDInput
            {...({} as any)}
            required
            type="text"
            label="Equipment Description"
            name="equipment_desc"
            value={payload?.equipment_desc || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <MDInput
            {...({} as any)}
            required
            type="number"
            label="Price"
            name="booking_price"
            value={payload?.booking_price || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <MDInput
            {...({} as any)}
            required
            type="number"
            label="Discount for the member"
            name="member_discount"
            value={payload?.member_discount || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            gap={3}
            pt={2}
            sx={widthStyle}
          >
            <MDTypography
              {...({} as any)}
              variant="h6"
              sx={{ fontSize: "14px", fontWeight: "400" }}
            >
              Upload Equipment Image
            </MDTypography>
            <MDInput
              {...({} as any)}
              type="file"
              name="files"
              margin="dense"
              onChange={handleChange}
              // multiple
            />

            {imagePreview ? (
              <img
                src={`${imagePreview}`}
                alt="Hall images"
                style={{
                  width: "50%",
                  aspectRatio: "3/2",
                  objectFit: "contain",
                }}
              />
            ) : (
              <MDTypography
                {...({} as any)}
                variant="h6"
                sx={{ fontSize: "14px", fontWeight: "400" }}
              >
                No latest images selected yet.
              </MDTypography>
            )}
          </Grid>
          {type === "edit" && payload?.equipment_images ? (
            <ImageList
              sx={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexWrap: "wrap",
                marginTop: "5px",
                padding: "5px",
                alignItems: "center",
                "@media (max-width: 961px)": {
                  justifyContent: "center",
                },
              }}
              rowHeight={164}
            >
              {type === "edit" &&
                payload?.equipment_images &&
                Array.isArray(payload?.equipment_images) &&
                payload?.equipment_images.map((file: string, index: number) => (
                  <ImageListItem key={index} style={{ width: "200px" }}>
                    <img
                      src={`${docUrl}${file}`}
                      alt={"Hall Images"}
                      loading="lazy"
                      style={{
                        width: "100%",
                        aspectRatio: "3/2",
                        objectFit: "contain",
                      }}
                    />
                    <MDButton
                      {...({} as any)}
                      component="button"
                      variant="body2"
                      onClick={() => handleRemoveImages(index)}
                      fullWidth
                      style={{ marginTop: "6px" }}
                    >
                      Remove the photo <DeleteIcon />
                    </MDButton>
                  </ImageListItem>
                ))}
            </ImageList>
          ) : null}
        </Grid>
        {(errors?.equipment_name && (
          <span style={{ fontSize: "12px", color: "red" }}>
            {errors?.equipment_name}
          </span>
        )) ||
          (errors?.equipment_quantity && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.equipment_quantity}
            </span>
          )) ||
          (errors?.equipment_desc && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.equipment_desc}
            </span>
          )) ||
          (errors?.booking_price && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.booking_price}
            </span>
          )) ||
          (errors?.member_discount && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.member_discount}
            </span>
          )) ||
          (errors?.equipment_images && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.equipment_images}
            </span>
          ))}
      </MDBox>
      <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
        <MDButton
          {...({} as any)}
          variant="gradient"
          color="info"
          onClick={handleAddUser}
        >
          {type === "add" ? "Add Equipment" : "Update"}
        </MDButton>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        </Box>
    </DashboardLayout>
  );
};

export default Equipment;
