// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import { DeleteHallById, GetHalls } from "../../Redux/action";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import Tooltip from "@mui/material/Tooltip";
import { useAppDispatch, useAppSelector, RootState } from "../../Redux/store";
import MDBox from "../../components/MDBox";
import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import MDButton from "../../components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Typography,
  Button
} from "@mui/material";
import { useSnackbar } from "notistack";

const HallManagement: React.FC = () => {
  const Author = ({
    image,
    name,
    capacity,
  }: {
    image: string;
    name: string;
    capacity: number;
  }) => (
    <MDBox {...({} as any)} display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar
        {...({} as any)}
        src={image}
        //   name={name}
        size="sm"
      />
      <MDBox {...({} as any)} ml={2} lineHeight={1}>
        <MDTypography
          {...({} as any)}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography {...({} as any)} variant="caption">
          having capacity of {capacity}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ price, discount }: { price: number; discount: number }) => (
    <MDBox {...({} as any)} lineHeight={1} textAlign="left">
      <MDTypography
        {...({} as any)}
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {price}
      </MDTypography>
      <MDTypography {...({} as any)} variant="caption">
        with discount of Rs. {discount}
      </MDTypography>
    </MDBox>
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { halls, loading } = useAppSelector(
    (store: RootState) => store.HallManagementReducer
  );
  const hallsData = halls || [];
  // console.log("hallsData", hallsData);

  const handleEditClick = (id: number | undefined) => {
    if (id) {
      navigate("/hall/edit/", { state: { type: "edit", id: id } });
    }
  };

  const handleClickOpen = (id: number) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = async () => {
    try {
      await dispatch<any>(DeleteHallById(deleteId!));
      dispatch<any>(GetHalls());
      handleClose();
      enqueueSnackbar("Hall is deleted successfully.", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting equipment:", error);
    }
  };

  const columns = [
    { Header: "hall name", accessor: "hall name", align: "left" },
    { Header: "address", accessor: "address", align: "left" },
    { Header: "booking price", accessor: "booking price", align: "left" },
    { Header: "booking availability", accessor: "status", align: "center" },
    {
      Header: "applicable deposit",
      accessor: "applicable deposit",
      align: "center",
    },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = hallsData?.map((hall) => ({
    "hall name": (
      <Author
        image={
          "https://klr.ac.in/wp-content/uploads/2015/11/dummy-user-1-200x200.jpg"
        }
        name={`${hall?.name}`}
        capacity={hall.capacity}
      />
    ),
    address: (
      <MDTypography
        {...({} as any)}
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {hall?.address}
      </MDTypography>
    ),
    "booking price": (
      <Job price={hall?.booking_price} discount={hall?.current_discount} />
    ),
    status: (
      <MDBox {...({} as any)} ml={-1}>
        <MDBadge
          {...({} as any)}
          badgeContent={hall?.booking_from}
          color={"success"}
          variant="gradient"
          size="sm"
        />
        <MDBadge
          {...({} as any)}
          color={"success"}
          badgeContent={hall?.booking_till}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    "applicable deposit": (
      <MDTypography
        {...({} as any)}
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {hall?.applicable_deposit}
      </MDTypography>
    ),
    action: (
      <>
        <MDBadge
          {...({} as any)}
          badgeContent={"Edit"}
          sx={{ cursor: "pointer" }}
          fontWeight="medium"
          onClick={() => handleEditClick(hall?.id)}
        />
        <MDBadge
          {...({} as any)}
          color={"dark"}
          badgeContent={"Delete"}
          sx={{ cursor: "pointer" }}
          fontWeight="medium"
          onClick={() => {
            if (hall?.id) handleClickOpen(hall?.id);
          }}
        />
      </>
    ),
  }));

  const handleGetHalls = async () => {
    try {
      await dispatch<any>(GetHalls());
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    handleGetHalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ ml: 1, mb: 2 }}
                  >
                    Hall Management
                  </Typography>
                <MDBox {...({} as any)} className="ticket_btnsHeaderCont">
                    <Tooltip title="View Hall Bookings">
                    <Button
                      onClick={() => navigate("/hall-management/booking")}
                      variant="outlined"
                      sx={{
                        mb: 2,
                        mr: 1,
                        color: "#000",
                        border: "1px black solid",
                        "&:hover": {
                          color: "#6c5ce7",
                          border: "2px #6c5ce7 solid"
                        }
                      }}
                    >
                      View Booking
                    </Button>
                    </Tooltip>
                  <Tooltip title="Add a new hall">
                    <Button
                      onClick={() => {
                        navigate("/hall/add", { state: { type: "add" } });
                      }}
                        variant="outlined"
                        sx={{
                          mb: 2,
                          mr: 1,
                          color: "#000",
                          border: "1px black solid",
                          "&:hover": {
                            color: "#6c5ce7",
                            border: "2px #6c5ce7 solid"
                          }
                        }}
                    >
                      Add Hall
                    </Button>
                  </Tooltip>
                </MDBox>
                </Box>
              </Box>
              <MDBox {...({} as any)} pt={3}>
                {hallsData && !loading ? (
                  hallsData?.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  ) : (
                    <Grid
                      style={{
                        display: "grid",
                        placeItems: "center",
                        margin: "15.7%",
                      }}
                    >
                      <img
                        src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                        width={"80px"}
                        height={"80px"}
                        alt=""
                      />
                      <MDTypography {...({} as any)} fontSize="12px">
                        No hall found. Please add a hall to continue.
                      </MDTypography>
                    </Grid>
                  )
                ) : (
                  <span style={{ fontSize: "15px", margin: "2rem" }}>
                    Loading...
                  </span>
                )}
              </MDBox>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this equipment?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <MDButton {...({} as any)} onClick={handleClose}>
                    Disagree
                  </MDButton>
                  <MDButton
                    {...({} as any)}
                    onClick={handleDeleteClick}
                    autoFocus
                  >
                    Agree
                  </MDButton>
                </DialogActions>
              </Dialog>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default HallManagement;
