// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import { DeleteEventsById, GetEventsByChamberId } from "../../Redux/action";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import Tooltip from "@mui/material/Tooltip";
import { useAppDispatch, useAppSelector, RootState } from "../../Redux/store";
import MDBox from "../../components/MDBox";
import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import MDButton from "../../components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Typography,
  Button
} from "@mui/material";
import { useSnackbar } from "notistack";

const EventManagement: React.FC = () => {
  const Author = ({
    image,
    name,
    date,
  }: {
    image: string;
    name: string;
    date: string;
  }) => (
    <MDBox {...({} as any)} display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar {...({} as any)} src={image} name={name} size="sm" />
      <MDBox {...({} as any)} ml={2} lineHeight={1}>
        <MDTypography
          {...({} as any)}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography {...({} as any)} variant="caption">
          happening on {date}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { eventsByChamberId, loading } = useAppSelector(
    (store: RootState) => store.EventReducer
  );
  const eventsData = eventsByChamberId || [];
  // console.log("eventsByChamberId", eventsByChamberId);

  const handleEditClick = (id: number | undefined) => {
    if (id) {
      navigate("/event-management/edit/", { state: { type: "edit", id: id } });
    }
  };

  const handleClickOpen = (id: number) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = async () => {
    try {
      await dispatch<any>(DeleteEventsById(deleteId!));
      dispatch<any>(GetEventsByChamberId());
      handleClose();
      enqueueSnackbar("Event is deleted successfully.", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const columns = [
    { Header: "event name", accessor: "event name", align: "left" },
    { Header: "agenda", accessor: "agenda", align: "left" },
    {
      Header: "registration date till",
      accessor: "registration date till",
      align: "center",
    },
    { Header: "time", accessor: "time", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = eventsData?.map((event) => ({
    "event name": (
      <Author
        image={
          "https://klr.ac.in/wp-content/uploads/2015/11/dummy-user-1-200x200.jpg"
        }
        name={`${event?.event_name}`}
        date={`${event?.event_date}`}
      />
    ),
    agenda: (
      <MDTypography
        {...({} as any)}
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {event?.event_agenda}
      </MDTypography>
    ),
    "registration date till": (
      <MDTypography {...({} as any)}>
        <MDBadge
          {...({} as any)}
          badgeContent={`${event?.event_registration_date}`}
          color={"success"}
          variant="gradient"
          size="sm"
        />
      </MDTypography>
    ),
    time: (
      // <MDBox {...({} as any)} ml={-1}>
      //   <MDBadge
      //     {...({} as any)}
      //     badgeContent={event?.event_time_from}
      //     color={"success"}
      //     variant="gradient"
      //     size="sm"
      //   />
      //   <MDBadge
      //     {...({} as any)}
      //     color={"success"}
      //     badgeContent={event?.event_time_till}
      //     variant="gradient"
      //     size="sm"
      //   />
      // </MDBox>
      <MDTypography
        {...({} as any)}
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {event?.event_time_from} - {event?.event_time_till}
      </MDTypography>
    ),
    action: (
      <>
        <MDBadge
          {...({} as any)}
          badgeContent={"Edit"}
          sx={{ cursor: "pointer" }}
          fontWeight="medium"
          onClick={() => handleEditClick(event?.event_id)}
        />
        <MDBadge
          {...({} as any)}
          color={"dark"}
          badgeContent={"Delete"}
          sx={{ cursor: "pointer" }}
          fontWeight="medium"
          onClick={() => {
            if (event.event_id) handleClickOpen(event?.event_id);
          }}
        />
      </>
    ),
  }));

  const handleGetEvents = async () => {
    try {
      await dispatch<any>(GetEventsByChamberId());
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    handleGetEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ ml: 1, mb: 2 }}
                  >
                    Event Management
                  </Typography>
                <MDBox {...({} as any)} className="ticket_btnsHeaderCont">
                  <Tooltip title="Add a new event">
                      <Button
                        onClick={() => {
                          navigate("/event-management/add", { state: { type: "add" } });
                        }}
                        variant="outlined"
                        sx={{
                          mb: 2,
                          mr: 1,
                          color: "#000",
                          border: "1px black solid",
                          "&:hover": {
                            color: "#6c5ce7",
                            border: "2px #6c5ce7 solid"
                          }
                        }}
                      >
                        Add Event
                      </Button>
                  </Tooltip>
                </MDBox>
              </Box>
              <MDBox {...({} as any)} pt={3}>
                {eventsData && !loading ? (
                  eventsData?.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  ) : (
                    <Grid
                      style={{
                        display: "grid",
                        placeItems: "center",
                        margin: "15.7%",
                      }}
                    >
                      <img
                        src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                        width={"80px"}
                        height={"80px"}
                        alt=""
                      />
                      <MDTypography {...({} as any)} fontSize="12px">
                        No event found. Please add an event to continue.
                      </MDTypography>
                    </Grid>
                  )
                ) : (
                  <span style={{ fontSize: "15px", margin: "2rem" }}>
                    Loading...
                  </span>
                )}
              </MDBox>
              </Box>
            </Card>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this event?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton {...({} as any)} onClick={handleClose}>
                Disagree
              </MDButton>
              <MDButton {...({} as any)} onClick={handleDeleteClick} autoFocus>
                Agree
              </MDButton>
            </DialogActions>
          </Dialog>
          </Grid>
        </Box>
    </DashboardLayout>
  );
};

export default EventManagement;
