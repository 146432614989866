import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import { GetUser } from "../../Redux/action";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { Tooltip } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../Redux/store";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDBadge from "../../components/MDBadge";
import MDAvatar from "../../components/MDAvatar";
import { IconButton, Box, Typography, Button } from '@mui/material';

import POPUploadUserCSV from "../../components/BulkUpload/POPUploadUserCSV";

const MemberShip: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const Author = ({
    image,
    name,
    email,
  }: {
    image: string;
    name: string;
    email: string;
  }) => (
    <MDBox {...({} as any)} display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar
        {...({} as any)}
        src={image}
        // name={name}
        size="sm"
      />
      <MDBox {...({} as any)} ml={2} lineHeight={1}>
        <MDTypography
          {...({} as any)}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography {...({} as any)} variant="caption">
          {email}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => (
    <MDBox {...({} as any)} lineHeight={1} textAlign="left">
      <MDTypography
        {...({} as any)}
        display="block"
        variant="caption"
        MD
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography {...({} as any)} variant="caption">
        {description}
      </MDTypography>
    </MDBox>
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const docUrl = "https://pixa-chambers-bucket.s3.ap-south-1.amazonaws.com/";
  const { user, loading } = useAppSelector(
    (store: RootState) => store.MembershipReducer
  );
  const usersData = user || [];
  // console.log(usersData, 'usersData');

  const handleEditClick = (id: number | undefined) => {
    if (id) {
      navigate("/membership/edit/", { state: { type: "edit", id: id } });
    }
  };

  const columns = [
    { Header: "member", accessor: "member", width: "45%", align: "left" },
    { Header: "phone number", accessor: "phone number", align: "left" },
    { Header: "organization", accessor: "organization", align: "left" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "subscription", accessor: "subscription", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = usersData?.map((userData) => ({
    member: (
      <Author
        // image={
        //   "https://klr.ac.in/wp-content/uploads/2015/11/dummy-user-1-200x200.jpg"
        // }
        image={`${docUrl}${userData?.logo}`}
        name={`${userData?.first_name} ${userData?.last_name}`}
        email={userData.email}
      />
    ),
    "phone number": (
      <MDTypography
        {...({} as any)}
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {userData?.phone_number}
      </MDTypography>
    ),
    organization: (
      <Job
        title={userData?.organization_name}
        description={userData?.organization_category}
      />
    ),
    status: (
      <MDBox {...({} as any)} ml={-1}>
        <MDBadge
          {...({} as any)}
          badgeContent={userData?.status === "online" ? "Active" : "Inactive"}
          color={userData?.status === "online" ? "success" : "dark"}
          variant="gradient"
          size="sm"
          // onClick={handleToggle}
          // style={{ cursor: 'pointer' }}
        />
      </MDBox>
    ),
    subscription: (
      <MDTypography
        {...({} as any)}
        // href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {userData?.subscription_from?.slice(0, 10)}
      </MDTypography>
    ),
    action: (
      <MDBadge
        {...({} as any)}
        badgeContent={"Edit"}
        sx={{ cursor: "pointer" }}
        fontWeight="medium"
        onClick={() => handleEditClick(userData?.user_id)}
      />
    ),
  }));

  const handleGetUser = async () => {
    try {
      await dispatch<any>(GetUser());
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (user) {
      handleGetUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <POPUploadUserCSV
        isDialog={isDisabled}
        onClose={setIsDisabled}
      />
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ ml: 1, mb: 2 }}
                  >
                  Membership
                </Typography>
                  <Button
                    onClick={() => navigate('/membership/add')}
                    variant="outlined"
                    sx={{
                      mb: 2,
                      mr: 1,
                      color: "#000",
                      border: "1px black solid",
                      "&:hover": {
                        color: "#6c5ce7",
                        border: "2px #6c5ce7 solid"
                      }
                    }}
                  >
                    Add Member
                  </Button>
                  <Button
                    onClick={() => {
                      setIsDisabled(!isDisabled)
                    }}
                    variant="outlined"
                    sx={{
                      mb: 2,
                      mr: 1,
                      color: "#000",
                      border: "1px black solid",
                      "&:hover": {
                        color: "#6c5ce7",
                        border: "2px #6c5ce7 solid"
                      }
                    }}
                  >
                    Upload CSV
                  </Button>
                </Box>
              </Box>
              <MDBox {...({} as any)} pt={3}>
                {usersData && !loading ? (
                  usersData?.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  ) : (
                    <Grid
                      style={{
                        display: "grid",
                        placeItems: "center",
                        margin: "15.7%",
                      }}
                    >
                      <img
                        src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                        width={"80px"}
                        height={"80px"}
                        alt=""
                      />
                      <MDTypography {...({} as any)} fontSize="12px">
                        No member found. Please add a member to continue.
                      </MDTypography>
                    </Grid>
                  )
                ) : (
                  <span style={{ fontSize: "15px", margin: "2rem" }}>
                    Loading...
                  </span>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default MemberShip;
